
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React from 'react';
import type { AppProps } from 'next/app';
import { ThemeProvider, StyleSheetManager, 
// eslint-disable-next-line import/named
DefaultTheme, } from 'styled-components';
import { FonomaBaseTheme } from '../modules/common';
import { RetopeBaseTheme } from '../modules/common/styles/themes/retope-base-theme';
import { FonomaGlobalStyle } from '../global-styles/fonoma-global-style';
import { TeloaGlobalStyle } from '../global-styles/teloa-global-style';
import { analytics } from '../modules/analytics/analytics';
import { FonomaDefaultSeo } from '../components/seo/fonoma-default-seo';
import { getEventName } from '../modules/util/web-metric/get-event-name';
import { PROJECT_ENVS } from '../modules/util/constants';
import isNode from 'detect-node';
import { Metric } from '../modules/util/web-metric/metric-interface';
import 'swiper/swiper-bundle.css';
import '@fontsource/inter/latin-300.css';
import '@fontsource/inter/latin-400.css';
import '@fontsource/inter/latin-600.css';
import '@fontsource/inter/latin-700.css';
import { ErrorBoundary, Provider } from '@rollbar/react';
import { rollbarConfig } from '../modules/rollbar/rollbar-config';
import { GlobalLoadingIndicatorProvider } from '../modules/global-indicator-provider/context';
import { ReviewsIoContextProvider } from '../components/reviews-io/reviews-io-context';
import isPropValid from '@emotion/is-prop-valid';
/**
 * Window interface to include the object CookieBot will inject.
 * Docs: https://www.cookiebot.com/en/developer/
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
    interface Window {
        Cookiebot: {
            consent: {
                necessary: boolean;
                preferences: boolean;
                statistics: boolean;
                marketing: boolean;
            };
            consented: boolean;
            declined: boolean;
            hasResponse: boolean;
            doNotTrack: boolean;
            regulations: {
                gdprApplies: boolean;
                ccpaApplies: boolean;
                lgpdApplies: boolean;
            };
            show(): void;
            hide(): void;
            renew(): void;
            getScript(url: string): string;
            runScripts(): void;
            withdraw(): void;
            submitCustomConsent(optinPreferences: boolean, optinStatistics: boolean, optinMarketing: boolean): void;
        };
    }
}
/**
 * Custom Nextjs App component to enable i18n. Injects the FonomaBaseTheme to all child components.
 */
function FonomaApp({ Component, pageProps }: AppProps) {
    let theme;
    let GlobalStyle;
    switch (process.env.NEXT_PUBLIC_PROJECT_NAME as string) {
        case 'teloa':
            theme = RetopeBaseTheme;
            GlobalStyle = TeloaGlobalStyle;
            break;
        case 'fonoma':
            theme = FonomaBaseTheme;
            GlobalStyle = FonomaGlobalStyle;
            break;
    }
    // This effect handles the relationship between the cookie consent
    // and segment (analytics). Until the user hasn't consented to the use of cookies
    // we will not load segment.
    React.useEffect(() => {
        const handleCookiebotResponse = () => {
            // Check if the user accepted the all cookies.
            // TODO: Add granularity to the segment's integrations
            // we load depending on the user's cookies. For example,
            // if the user only accepted statistics cookies, load amplitude,
            // but if they also accepted marketing load Google, Facebook and the rest.
            // TODO: We should try to load intercom no matter what, however,
            // they will set their own cookies and this could cause trouble.
            if (window.Cookiebot &&
                window.Cookiebot.consented &&
                window.Cookiebot.consent.preferences &&
                window.Cookiebot.consent.statistics &&
                window.Cookiebot.consent.marketing) {
                analytics.load();
            }
            window.removeEventListener('CookiebotOnAccept', handleCookiebotResponse);
        };
        // Check if the user has already accepted the cookies
        if (window.Cookiebot && window.Cookiebot.hasResponse) {
            handleCookiebotResponse();
        }
        else {
            window.addEventListener('CookiebotOnAccept', handleCookiebotResponse);
        }
    }, []);
    return (<Provider config={rollbarConfig}>
            <ErrorBoundary>
                <ThemeProvider theme={theme as DefaultTheme}>
                    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                        <GlobalLoadingIndicatorProvider initialState={{
            show: false,
            opacity: 0.75,
            color: '#ffffff',
        }}>
                            <ReviewsIoContextProvider>
                                <>
                                    <FonomaDefaultSeo />
                                    <Component {...pageProps}/>
                                    <GlobalStyle />
                                </>
                            </ReviewsIoContextProvider>
                        </GlobalLoadingIndicatorProvider>
                    </StyleSheetManager>
                </ThemeProvider>
            </ErrorBoundary>
        </Provider>);
}
export function reportWebVitals(metric: Metric): void {
    if (!isNode) {
        if (process.env.NEXT_PUBLIC_PROJECT_ENV !== PROJECT_ENVS.PRODUCTION) {
            console.log(metric);
        }
        void analytics.track(getEventName(metric), {
            ...metric,
            ...{ url: window.location.pathname },
        });
    }
}
// This implements the default behavior from styled-components v5
// https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
function shouldForwardProp(propName, target) {
    if (typeof target === 'string') {
        // For HTML elements, forward the prop if it is a valid HTML attribute
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
}
const __Next_Translate__Page__19174a9abf9__ = FonomaApp;

    export default __appWithI18n(__Next_Translate__Page__19174a9abf9__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  